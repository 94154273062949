import React from 'react'
import { saveAs } from 'file-saver'
import MUIDataTable from "mui-datatables"
import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteIcon from '@material-ui/icons/Delete'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import ConfirmDialog from '../components/ConfirmDialog'

function CallRecordingTable(props) {
  const [openIds, setOpenIds] = React.useState(null)
  const [selectedRows, setSelectedRows] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(0)
  const [downloading, setDownloading] = React.useState(false)
  
  function handleClose () {
    setOpenIds(null)
  }
  
  function handleDeleteConfirm () {
    if (props.onDelete) {
      (openIds || []).map(id => props.onDelete(id))
    }
    handleClose()
  }
  
  function handleSingleDelete (id) {
    setOpenIds([id])
  }

  function handleMultipleDelete (ids) {
    setOpenIds(ids)
  }

  function handleSingleDownload (id) {
    const recording = props.rows.find(row => row.id === id)
    if (props.onDownload && recording) {
      props
        .onDownload(recording.publicURI)
        .then(blob => saveAs(blob, recording.filename))
    }
  }

  function handleMultipleDownload (ids) {
    const list = ids || []
    if (props.onDownload && list.length > 1) {
      const recording = props.rows.find(row => row.id === ids[0])
      setDownloading(true)
      props
        .onPostDownload(recording.publicURI.replace(/\/\d+\//, '/'), list)
        .then(blob => saveAs(blob, 'recordings.zip'))
        .then(() => setDownloading(false))
        .catch(() => setDownloading(false))
    } else if (list.length === 1) {
      list.map(id => handleSingleDownload(id))
    }
  }

  const columns = [
    {
      name: 'filename',
      label: 'Filename',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'idpbx',
      label: 'ID PBX',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'caller',
      label: 'Caller',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'called',
      label: 'Called',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'timestamp',
      label: 'Created on',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'size',
      label: 'Size',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        download: false
      }
    }
  ]
  
  const options = {
    filterType: 'multiselect',
    selectableRows: 'multiple',
    rowsSelected: selectedRows,
    onTableChange: (action, state) => {
      setPage(state.page)
      setRowsPerPage(state.rowsPerPage)
    },
    onRowsSelect: (rowsSelected, allRows) => {
      const start = page * rowsPerPage
      const selectable = allRows.slice(start, start + rowsPerPage)
      setSelectedRows(selectable.map(row => row.dataIndex))
    },
    isRowSelectable: (dataIndex, selectedRows) => {
      const isSelected = selectedRows.data.find(item => item.dataIndex === dataIndex)
      return isSelected || selectedRows.data.length < rowsPerPage
    },
    customToolbarSelect: (selectedRows) => {
      const ids = selectedRows.data.map(item => props.rows[item.dataIndex].id)
      return (
        <div style={{ padding: '0 1.5rem' }}>
          <IconButton>
            {
              downloading ? (
                <CircularProgress size={25} />
              ) : (
                <CloudDownloadIcon onClick={() => handleMultipleDownload(ids)} />
              )
            }
          </IconButton>
          <IconButton onClick={() => handleMultipleDelete(ids)}>
            <DeleteIcon/>
          </IconButton>
        </div>
      )
    },
    downloadOptions: {
      filename: 'recordings.csv'
    },
    print: false
  }
  
  const formatRows = (rows, onPlayClick) => rows.map(row => {
    return Object.assign({}, row, {
      size: row.size >= 1000000
        ? `${Number(row.size / 1000000.0).toFixed(2)} MB`
        : row.size >= 1000
          ? `${Number(row.size / 1000.0).toFixed(2)} kB`
          : `${Number(row.size).toFixed(2)} B`,
      actions: (
        <React.Fragment>
          {row.isPlaying && <audio autoPlay><source src={row.publicURI} /></audio>}
          <IconButton onClick={() => onPlayClick && onPlayClick(row.filename)}>
            {
              row.isPlaying
                ? <StopIcon />
                : <PlayArrowIcon/>
            }
          </IconButton>
          <IconButton onClick={() => handleSingleDownload(row.id)}>
            <CloudDownloadIcon/>
          </IconButton>
          {
            props.isAdmin &&
            <IconButton onClick={() => handleSingleDelete(row.id)}>
              <DeleteIcon/>
            </IconButton>
          }
        </React.Fragment>
      )
    })
  })
  return (
    <React.Fragment>
      <MUIDataTable
        title="Call recordings"
        data={formatRows(props.rows, props.onPlayClick, props.onDownloadClick, props.onDeleteClick)}
        columns={columns}
        options={options}
      />
      <ConfirmDialog
        ids={openIds}
        onClose={handleClose}
        onConfirm={handleDeleteConfirm}
      />
    </React.Fragment>
  )
}

export default CallRecordingTable
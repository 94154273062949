import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AddUserButton from '../components/AddUserButton'

function Sidebar(props) {
  const useStyles = makeStyles(theme => ({
    drawer: {
      width: props.width,
      flexShrink: 0,
    },
    drawerPaper: {
      width: props.width,
      backgroundColor: '#f6f6f6',
    }
  }))

  const classes = useStyles()

  const [value, setValue] = React.useState(0)

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Logs" />
        <Tab label="Users" />
      </Tabs>
      <Divider />
        {
          value === 0 &&
          <List>
            {props.logs.map(log => (
              <ListItem divider key={log.id}>
                <ListItemText
                  primary={`${log.filename} (${log.idpbx})`}
                  secondary={`${log.action} by ${log.user} on ${new Date(log.timestamp).toLocaleString()}`}
                />
              </ListItem>
            ))}
          </List>
        }
        {
          value === 1 &&
          <React.Fragment>
            <List>
              {props.users.map(user => (
                <ListItem divider key={user.id}>
                  <ListItemText
                    primary={`${user.email} (${user.permissions === 1 ? 'Listener' : 'Admin'})`}
                    secondary={`${user.firstname} ${user.lastname} on ${(user.idpbxs || '').split(',').join(', ')}`}
                  />
                </ListItem>
              ))}
            </List>
            {false && <AddUserButton onClick={() => props.onAddUserClick && props.onAddUserClick()}/>}
          </React.Fragment>
        }
    </Drawer>
  )
}

export default Sidebar
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

function AppHeader(props) {
  const classes = useStyles()
  return (
    <AppBar className={classes.root} position="static">
      <Toolbar>
        {(props.user.permissions === 100) &&
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={() => props.onMenuClick && props.onMenuClick()}>
          <MenuIcon />
        </IconButton>}
        <Typography variant="h6" className={classes.title}>
          CloudPBX - CallRec
        </Typography>
        <Typography>
          {props.user.email}
        </Typography>
        <IconButton edge="end" color="inherit" aria-label="Logout" onClick={() => props.onLogoutClick && props.onLogoutClick()}>
          <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { StoreProvider } from './Store'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

console.log(`Accessing API at ${process.env.REACT_APP_API_URL}...`)

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#1c9cd8'
    }
  },
})

ReactDOM.render(
  <StoreProvider>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

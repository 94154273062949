import React from 'react'

export const Store = React.createContext();

const initialState = {
  clear: null,
  loggedAccount: null,
  jwt: null,
  areLogsVisible: false,
  logs: [],
  users: [],
  recordings: [],
  playing: null
}

function reducer (state, action) {
  switch (action.type) {
    case 'FINISH_BOOTSTRAP':
      return { ...state, clear: action.payload }
    case 'LOGIN':
      return { ...state, loggedAccount: action.payload.account, jwt: action.payload.jwt }
    case 'LOGOUT':
      if (state.clear) clearInterval(state.clear)
      return {
        ...state,
        loggedAccount: null,
        jwt: null,
        areLogsVisible: false,
        clear: null,
        playing: null,
        recordings: [],
        users: [],
        logs: []
      }
    case 'TOGGLE_LOGS':
      return { ...state, areLogsVisible: !state.areLogsVisible }
    case 'FETCH_LOGS':
      return { ...state, logs: action.payload }
    case 'FETCH_USERS':
      return { ...state, users: action.payload }
    case 'FETCH_RECORDINGS':
      return {
        ...state,
        recordings: action.payload.map(record => Object.assign({}, record, {
          publicURI: `${process.env.REACT_APP_API_URL}/recordings/${record.id}/download?jwt=${state.jwt}`
        }))
      }
    case 'PLAY_RECORDING':
      return { ...state, playing: action.payload !== state.playing ? action.payload : null }
    case 'DELETE_RECORDING':
      return { ...state, recordings: state.recordings.filter(recording => recording.id !== action.payload) }
    default:
      return state
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }
  return (
    <Store.Provider value={value}>
      {props.children}
    </Store.Provider>
  )
}
import React from 'react'
import fetch from 'node-fetch'
import { Store } from './Store'
import LoginForm from './components/LoginForm'
import AppHeader from './components/AppHeader'
import Sidebar from './components/Sidebar'
import CallRecordingTable from './components/CallRecordingTable'
import RetentionDisclaimer from './components/RetentionDisclaimer'
import './App.css'

const API_URL = process.env.REACT_APP_API_URL || 'localhost:3000'
const AUTH_JWT_STORAGE_KEY = 'cloudpbx-callrec-auth-jwt'
const LEFT_DRAWER_WIDTH = 420
const FETCH_INTERVAL = 30000 // 30s
const ADMIN_PERMS = 100

function App() {
  const { state, dispatch } = React.useContext(Store)

  const fetchLogs = () => {
    return fetch(`${API_URL}/recordings/logs`, {
      method: 'get',
      headers: {
        "Authorization": "Bearer " + state.jwt
      }
    })
      .then(res => res.json())
      .then(payload => {
        return dispatch({
          type: 'FETCH_LOGS',
          payload
        })
      })
  }

  const fetchUsers = () => {
    return fetch(`${API_URL}/auth/users`, {
      method: 'get',
      headers: {
        "Authorization": "Bearer " + state.jwt
      }
    })
      .then(res => res.json())
      .then(payload => {
        return dispatch({
          type: 'FETCH_USERS',
          payload
        })
      })
  }

  const fetchRecordings = () => {
    return fetch(`${API_URL}/recordings`, {
      method: 'get',
      headers: {
        "Authorization": "Bearer " + state.jwt
      }
    })
      .then(res => res.json())
      .then(payload => {
        return dispatch({
          type: 'FETCH_RECORDINGS',
          payload
        })
      })
  }

  const login = (email, password, rememberMe) => {
    return fetch(`${API_URL}/auth`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        email,
        password
      })
    })
      .then(res => res.json())
      .then(payload => {
        // TODO: if rememberMe === true -> save JWT in cookie
        if (rememberMe && payload.jwt) {
          localStorage.setItem(AUTH_JWT_STORAGE_KEY, payload.jwt)
        }
        return dispatch({
          type: 'LOGIN',
          payload
        })
      })
  }

  const loginWithJWT = (jwt) => {
    return fetch(`${API_URL}/auth`, {
      method: 'post',
      headers: {
        authorization: 'Bearer ' + jwt
      }
    })
      .then(res => res.json())
      .then(payload => {
        return dispatch({
          type: 'LOGIN',
          payload
        })
      })
  }

  const inviteUser = (email, permissions) => {
    // TODO
  }

  const toggleLogs = () => {
    return dispatch({
      type: 'TOGGLE_LOGS'
    })
  }

  const logout = () => {
    // TODO: ask confirmation first
    localStorage.removeItem(AUTH_JWT_STORAGE_KEY)
    return dispatch({
      type: 'LOGOUT'
    })
  }

  const playRecording = filename => {
    return dispatch({
      type: 'PLAY_RECORDING',
      payload: filename
    })
  }

  const downloadRecording = uri => {
    return fetch(uri, {
      headers: {
        authorization: 'Bearer ' + state.jwt
      }
    })
      .then(res => res.blob())
  }

  const downloadMultiRecording = (uri, ids) => {
    return fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + state.jwt
      },
      body: JSON.stringify({
        ids
      })
    }).then(res => res.blob())
  }

  const deleteRecording = id => {
    return fetch(`${API_URL}/recordings/${id}`, {
      method: 'delete',
      headers: {
        authorization: 'Bearer ' + state.jwt
      }
    })
      .then(() => {
        return dispatch({
          type: 'DELETE_RECORDING',
          payload: id
        })
      })
  }

  const finishBootstrap = (clearInterval) => {
    console.log(`Bootstrap finished.`)
    return dispatch({
      type: 'FINISH_BOOTSTRAP',
      payload: clearInterval
    })
  }

  const fetchData = () => {
    fetchRecordings()
    if (state.loggedAccount.permissions === ADMIN_PERMS) {
      fetchLogs()
      fetchUsers()
    }
  }

  React.useEffect(() => {
    if (state.loggedAccount && !state.clear) {
      fetchData()
      finishBootstrap(setInterval(fetchData, FETCH_INTERVAL))
    }
  })

  if (!state.loggedAccount) {
    const jwt = localStorage.getItem(AUTH_JWT_STORAGE_KEY)
    if (jwt) {
      loginWithJWT(jwt)
    }
    return (
      <LoginForm
        onSubmitLogin={(email, password, rememberMe) => login(email, password, rememberMe)}
      />
    )
  }

  return (
    <div className="App">
      {
        state.loggedAccount.permissions === ADMIN_PERMS &&
        <Sidebar
          logs={state.logs}
          users={state.users}
          width={LEFT_DRAWER_WIDTH}
          open={state.areLogsVisible}
          onAddUserClick={(email, permissions) => inviteUser(email, permissions)}
        />
      }
      <div className="App-wrapper" style={{ marginLeft: state.areLogsVisible ? LEFT_DRAWER_WIDTH : 0}}>
        <AppHeader
          user={state.loggedAccount}
          onMenuClick={() => toggleLogs()}
          onLogoutClick={() => logout()}
        />
        <div className="App-content">
          <RetentionDisclaimer/>
          <CallRecordingTable
            isAdmin={state.loggedAccount.permissions === ADMIN_PERMS}
            rows={state.recordings.map(record => Object.assign({}, record, { isPlaying: record.filename === state.playing, timestamp: new Date(record.timestamp).toLocaleString() }))}
            onPlayClick={filename => playRecording(filename)}
            onDownload={uri => downloadRecording(uri)}
            onPostDownload={(uri, ids) => downloadMultiRecording(uri, ids)}
            onDelete={id => deleteRecording(id)}
          />
        </div>
      </div>
    </div>
  )
}

export default App

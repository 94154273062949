import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  disclaimer: {
    marginBottom: theme.spacing(3)
  }
}))

function RetentionDisclaimer(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  return (
    <Collapse in={open}>
      <Alert
        className={classes.disclaimer}
        severity="info"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>Retention disclaimer</AlertTitle>
        <div>
          Recordings are kept for a period of <b>90 days</b>. After this time, recordings are automatically <b>deleted permanently</b>.
        </div>
      </Alert>
    </Collapse>
  )
}

export default RetentionDisclaimer